import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getTeamAndTournament } from '../../api/RouteAPI';
import GetAllUserInTeam from '../../components/forms/ForAdminTeamPage/GetAllUserInTeam/GetAllUserInTeam';
import DeleteUserFromTeam from '../../components/forms/ForAdminTeamPage/DeleteUserFromTeam/DeleteUserFromTeam';
import ViewFightInTournament from '../../components/forms/ViewFightInTournament/ViewFightInTournament';

const TeamAdminPage = () => {
  const [teamInfo, setTeamInfo] = useState({});
  const [error, setError] = useState(null);
  const { tournamentId, teamId } = useParams();
  const token = localStorage.getItem('token');
  const [userId, setUserDelete] = useState(null);

  const handleDeleteUser = (userId) => {
    setUserDelete(userId);
  };

  useEffect(() => {
    const fetchTeamInfo = async () => {
      try {
        const response = await getTeamAndTournament(tournamentId, teamId, token);
        setTeamInfo(response.team_and_tournament);
      } catch (error) {
        setError('Erreur lors de la récupération des informations de l\'équipe et du tournoi');
        console.error(error);
      }
    };

    fetchTeamInfo();
  }, [tournamentId, teamId, token]);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="text-white">
      <div className="flex items-center justify-between bg-gris p-4 mb-6">
        <div className="flex items-center">
          <img src={teamInfo.tournament ? teamInfo.tournament.gameIcon : ''} alt="icon" className="w-8 h-8 mr-4" />
          <span className="text-sm font-semibold">
            Nom de Lan: <div className='text-orange_FFA800 italic'>{teamInfo.tournament ? teamInfo.tournament.name_tournament : 'Chargement...'}</div>
          </span>
        </div>
        <span className="text-sm">organisateur: <span className="italic text-orange_FFA800">{teamInfo.tournament ? teamInfo.tournament.creatorPseudo : 'Chargement...'}</span></span>
        <span className="text-sm">status: <span className="italic text-orange_FFA800">{teamInfo.tournament ? teamInfo.tournament.status : 'Chargement...'}</span></span>
        /
        <span className="text-sm">Classement</span>
        <span className="text-sm">Les équipes</span>
        <span className="text-sm">Planning tournoi</span>
      </div>

      <h1>{teamInfo.team ? teamInfo.team.name_team : 'Chargement...'}</h1>

      {teamInfo.team && (
        <div>
          <h2>Informations sur l'équipe</h2>
          <p>ID de l'équipe : {teamInfo.team.id_team}</p>
          <p>Nom de l'équipe : {teamInfo.team.name_team}</p>
          <p>ID du leader : {teamInfo.team.id_user_leader}</p>
          <p>Créé le : {new Date(teamInfo.team.created_at).toLocaleString()}</p>
          <p>Mis à jour le : {new Date(teamInfo.team.updated_at).toLocaleString()}</p>
        </div>
      )}

      {teamInfo.tournament && (
        <div>
          <h2>Informations sur le tournoi</h2>
          <p>ID du tournoi : {teamInfo.tournament.id_tournament}</p>
          <p>Nom du tournoi : {teamInfo.tournament.name_tournament}</p>
          <p>Status : {teamInfo.tournament.status}</p>
          <p>Date de fin d'inscription : {new Date(teamInfo.tournament.inscription_date_end).toLocaleString()}</p>
          <p>ID du jeu : {teamInfo.tournament.id_game}</p>
          <p>ID de l'équipe gagnante : {teamInfo.tournament.id_team_winner || 'Pas encore déterminé'}</p>
          <p>Créé le : {new Date(teamInfo.tournament.created_at).toLocaleString()}</p>
          <p>Mis à jour le : {new Date(teamInfo.tournament.updated_at).toLocaleString()}</p>
        </div>
      )}

      <br />
      <br />

      <GetAllUserInTeam teamId={teamId} onDeleteUser={handleDeleteUser} />

      <br />
      <br />

      <DeleteUserFromTeam teamId={teamId} userId={userId} />

      <br />
      <br />

      <ViewFightInTournament tournamentId={tournamentId} />
    </div>
  );
};

export default TeamAdminPage;
