import React, { useState, useEffect } from 'react';
import { LoginUser } from '../../../api/RouteAPI';
import Modal from 'react-modal';
import './LoginForm.css';
import RegisterForm from '../RegisterForm/RegisterForm';

import IconUser from '../../../assets/icons/bx-user.png';
import IconUserOrange from '../../../assets/icons/bxs-user-orange.png';


Modal.setAppElement('#root'); 

function LoginForm({ isModalOpen, setIsModalOpen, setPseudo, setIsAuthenticated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});  

  const handleClick = async () => {
    const userData = { email, password };
    try {
      const response = await LoginUser(userData);
      if (response.status_code === 403) {
        setErrorMessage({ general: 'Information non valide' });
      } else if (response.errorsList) {
        const response_errorsList = response.errorsList;
        setErrorMessage(response_errorsList);
        console.log(response_errorsList);
      } else {
        console.log(response);
        localStorage.setItem('token', response.token);
        localStorage.setItem('pseudo', response.user.pseudo); 
        localStorage.setItem('userId', response.user.id); 
        localStorage.setItem('isAuthenticated', 'true');
        setPseudo(response.user.pseudo); // Mettre à jour l'état local du pseudo
        setIsAuthenticated(true); // Mettre à jour l'état d'authentification
        setIsModalOpen(false); // Fermer la modal après la connexion réussie
        window.location.reload();
      }
    } catch (errors) {
      console.log(errors);
    }
  };

  return (
    <div>
      <button onClick={() => setIsModalOpen(true)} 
        className="ml-6 inline-flex items-center px-3 py-2 text-xs font-semibold text-white text-poppins shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange_FFA800 border border-orange_FFA800 rounded-md hover:text-orange_FFA800 transition duration-500 ease-in-out" 
      >
        Se connecter
        <span 
          className='text-orange_FFA800 font-poppins text-base font-bold'
        >
          .
        </span>
      </button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="md:h-2/4 md:w-4/12 grid-cols-2 m-auto bg-[#1C1C1C] mt-20 mb-12 rounded-lg "    
        overlayClassName="fixed inset-0 bg-black bg-opacity-70 z-[1100]"
      >


<div className='text-white font-poppins font-extrabold text-4xl text-center pt-8'>Connection<span className='text-orange_FFA800'>.</span></div>

      <div className='mt-5 ml-[6em]'>
        <label className='labelInput font-poppins text-white font-bold text-xs'>Email</label>
        <input 
          type="email" 
          value={email} onChange={e => setEmail(e.target.value)} 
          className='flex bg-[#1C1C1C] p-[1vh] border-[1.2px] border-[#4F4F4F] rounded-[6px] w-[24vw] text-white font-poppins font-medium mt-1'/>
          {errorMessage.email &&
            <div className='errorMessage'>{errorMessage.email.join(', ')}</div>
          }
      </div>

      <div className='mt-5 ml-[6em]'>
              <label className='labelInput font-poppins text-white font-bold text-xs'>Mot de passe</label>
              <div className='relative'>
                <input 
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  className='flex bg-[#1C1C1C] p-[1vh] border-[1.2px] border-[#4F4F4F] rounded-[6px] w-[24vw] text-white font-poppins font-medium mt-1'
                />
                <button 
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className='absolute inset-y-0 right-0 flex items-center px-24 text-white'
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>
              {errorMessage.password && 
                <div className='errorMessage'>{errorMessage.password.join(', ')}</div>
              }
      </div>



      <button type="button" value="Submit" onClick={handleClick} className='btnSubmit text-orange_FFA800 font-poppins text-2xl font-bold flex items-center mx-auto mt-8'>Se connecter</button>
        <p className='mt-3 font-poppins text-sm font-extrabold text-gris_F1F1F1 ml-[14em] flex items-center'>
            je n'ai pas de compte
            <span className='text-orange_FFA800'>.</span>
            <span ><RegisterForm /></span>
        </p>
        

      </Modal>
    </div>
  );
}













function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pseudo, setPseudo] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isHoveredUser, setIsHoveredUser] = useState(false);


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    const savedPseudo = localStorage.getItem('pseudo');
    if (token && isAuthenticated && savedPseudo) {
      setPseudo(savedPseudo);
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('pseudo');
    localStorage.removeItem('userId');
    localStorage.removeItem('isAuthenticated');
    setIsAuthenticated(false);
    setPseudo('');
    window.location.reload(); 
  };
  

  return (
    <div className="relative">
      {isAuthenticated ? (
          <a
            className="ml-6 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:text-orange_FFA800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange_FFA800 transition duration-500 ease-in-out cursor-pointer	"
            onMouseEnter={() => setIsHoveredUser(true)}
            onMouseLeave={() => setIsHoveredUser(false)}
            onClick={toggleMenu}
          >
            <div className="relative h-5 w-5 mr-2">
              <img
                src={IconUser}
                alt="Icon User"
                className={`absolute inset-0 h-full w-full transition-opacity duration-500 ease-in-out ${isHoveredUser ? 'opacity-0' : 'opacity-100'}`}
              />
              <img
              src={IconUserOrange}
              alt="Icon User Orange"
              className={`absolute inset-0 h-full w-full transition-opacity duration-500 ease-in-out ${isHoveredUser ? 'opacity-100' : 'opacity-0'}`}
              />
            </div>
          {pseudo}
          
            {isMenuOpen && (
              <div className="absolute right-0 top-0 mt-10 ml-8 w-36 rounded-md shadow-lg bg-gray-900">
                <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <button
                    className="block w-full text-align px-4 py-2 text-sm font-semibold text-white hover:text-orange_FFA800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange_FFA800 transition duration-500 ease-in-out cursor-pointer"
                    onClick={handleLogout}
                  >
                    Se déconnecter
                  </button>
                </div>
              </div>
            )}
          </a>
        
      ) : (
        <LoginForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setPseudo={setPseudo}
          setIsAuthenticated={setIsAuthenticated}
        />
      )}
    </div>
  );
}

export default App;