// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pasDeCompte{
    margin-left:7vw;
}


.sisncrire{
    margin-left: 0.8vw;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/LoginForm/LoginForm.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;;AAGA;IACI,kBAAkB;AACtB","sourcesContent":[".pasDeCompte{\n    margin-left:7vw;\n}\n\n\n.sisncrire{\n    margin-left: 0.8vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
