import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllTournaments, getAllUsers } from '../../../../api/RouteAPI';
import LogoLol from '../../../../assets/img/icon-lol.png';
import LogoDestiny from '../../../../assets/img/icon-destiny.png';
import imgDestiny1v1 from '../../../../assets/img/destiny_1v1.webp';
import imgDestiny2v2_1 from '../../../../assets/img/destiny_2v2.webp';
import imgDestiny2v2_2 from '../../../../assets/img/destiny_2v2_1.webp';
import imgDestiny3v3 from '../../../../assets/img/destiny_3v3.webp';
import imgDestiny4v4 from '../../../../assets/img/destiny_4v4.webp';
import imgDestiny6v6 from '../../../../assets/img/destiny_6v6.webp';
import imgLoL_1 from '../../../../assets/img/league_of_legend_1.jpg';
import imgLoL_2 from '../../../../assets/img/league_of_legend_2.jpg';
import imgLoL_3 from '../../../../assets/img/league_of_legend_3.jpg';

function TournamentList() {
    const [tournaments, setTournaments] = useState([]);
    const [usernames, setUsernames] = useState({});

    useEffect(() => {
        const fetchTournamentsAndUsers = async () => {
            try {
                const [tournamentsData, usersData] = await Promise.all([
                    getAllTournaments(),
                    getAllUsers()
                ]);

                setTournaments(tournamentsData);

                const usernamesMap = {};
                usersData.users.forEach(user => {
                    usernamesMap[user.id] = user.pseudo;
                });
                setUsernames(usernamesMap);
            } catch (error) {
                console.error(error);
            }
        };

        fetchTournamentsAndUsers();
    }, []);

    const findUserPerTeamByGameId = (id) => {
        if ([1, 3, 4, 8].includes(id)) {
            return 1;
        } else if ([5, 9].includes(id)) {
            return 2;
        } else if ([6, 10].includes(id)) {
            return 3;
        } else if ([7, 11].includes(id)) {
            return 4;
        } else if ([2, 12].includes(id)) {
            return 5;
        } else if ([13].includes(id)) {
            return 6;
        } else {
            return 0;
        }
    };

    const getGameIcon = (id) => {
        if ([1, 2, 3, 4, 5, 6, 7].includes(id)) {
            return LogoLol;
        } else if ([8, 9, 10, 11, 12, 13].includes(id)) {
            return LogoDestiny;
        } else {
            return '';
        }
    };

    const getRandomImage = (id) => {
        if ([1, 2, 3, 4, 5, 6, 7].includes(id)) {
            const images = [imgLoL_1, imgLoL_2, imgLoL_3];
            return images[Math.floor(Math.random() * images.length)];
        } else if (id === 8) {
            return imgDestiny1v1;
        } else if (id === 9) {
            const images = [imgDestiny2v2_1, imgDestiny2v2_2];
            return images[Math.floor(Math.random() * images.length)];
        } else if (id === 10) {
            return imgDestiny3v3;
        } else if (id === 11) {
            return imgDestiny4v4;
        } else if ([12, 13].includes(id)) {
            return imgDestiny6v6;
        } else {
            return imgLoL_3; // Default image
        }
    };
 
    const getStatusClass = (status) => {
        switch (status) {
            case 'inscription':
                return 'text-poppins text-xs font-bold text-green-500';
            case 'en cours':
                return 'text-poppins text-xs font-light text-red-800 ';
            case 'terminé':
                return 'text-poppins text-xs font-bold text-red-500';
            default:
                return 'text-poppins text-xs font-bold text-red-500';
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 'inscription':
                return '● Inscription';
            case 'en cours':
                return '● En cours';
            case 'terminé':
                return '● Terminé';
            default:
                return 'Statut inconnu.';
        }
    };

    return (
        <div 
            className="mx-auto 
            px-4 sm:px-32 lg:px-60 xl:px-72 2xl:px-80 
            2xl:w-full sm:w-full
            "            
        >
            <ul className='grid grid-cols-1 2xl:grid-cols-2 gap-8 2xl:gap-18'>
                {tournaments.map(tournament => {
                    const usersPerTeam = findUserPerTeamByGameId(tournament.id_game);
                    const gameIcon = getGameIcon(tournament.id_game);
                    const statusClass = getStatusClass(tournament.status);
                    const statusText = getStatusText(tournament.status);
                    const randomImage = getRandomImage(tournament.id_game);
                    const creatorPseudo = usernames[tournament.id_user_createur] || 'Chargement...';

                    return (
                        <li key={tournament.id_tournament} className="overflow-hidden z-10 rounded-md border-[#2B2B2B] border border-opacity-30 bg-custom-gradient from-right via-mid to-right">
                            <div className="relative">
                                <img
                                    src={randomImage}
                                    alt="Tournament"
                                    className="
                                    w-full h-[220px] 
                                    sm:h-[320px]  
                                    md:h-[280px] 
                                    lg:h-[370px] 
                                    xl:h-[420px]
                                    2xl:w-[600px] 2xl:h-[280px] 
                                    object-cover mx-auto px-4 pt-10 rounded-2xl"
                                />
                                <div className="absolute top-1 left-2 pr-4 pl-4 pt-1 pb-1 ml-2  rounded  bg-gradient-to-b from-background_color_top to-background_color_bottom">
                                    <img src={gameIcon} alt="Game Icon" className="w-6 h-6 " />
                                </div>
                                <div className={`absolute top-0 left-7 p-1 mt-2 ml-12 rounded-lg ${statusClass}`}>
                                    {statusText}
                                </div>
                                <div className="absolute top-0 right-0 p-2 mt-1 mr-2  bg-opacity-60 text-white text-xs font-light rounded-lg">
                                    <div>   
                                        Team
                                        <span className='text-orange_FFA800 font-semibold'> {usersPerTeam} v {usersPerTeam}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-4 flex justify-between items-center text-white">
                                <div className="flex items-center space-x-4 flex-shrink-0">                                    
                                    <div>
                                        <p className="text-xs font-light">Nom de Lan :</p>
                                        <p className="text-xs font-semibold italic">{tournament.name_tournament}</p>
                                    </div>
                                </div>                            
                                <Link
                                    to={`/tournament/${tournament.id_tournament}`}
                                    className="flex justify-center items-center inline-center rounded-md px-3 py-1 text-xs border border-orange_FFA800 text-orange_FFA800 font-medium ring-1 ring-inset ring-gray-500/10 transition duration-300 hover:bg-orange_FFA800 hover:text-dark_grey">                               
                                    Voir
                                </Link>
                                <div className="text-right flex-shrink-0">
                                    <p className="text-xs font-light">organisateur :</p>
                                    <p className="text-xs font-semibold italic">{creatorPseudo}</p>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default TournamentList;
