import React from 'react';
import './LoginPage.css';
import LoginForm from '../../components/forms/LoginForm/LoginForm';

function LoginPage() {
  return (
    <div className="LoginPage ">
      <LoginForm />
      <div className='RegisterBlock'>
      </div>
    </div>
  );
}

export default LoginPage;