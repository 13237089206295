import React, { useEffect, useState } from 'react';
import { getUserTeamsAndTournaments } from '../../../api/RouteAPI';
import { Link } from 'react-router-dom';

import IconeTeam from '../../../assets/icons/bxs-group.png';

function ViewTeamsJoined() {
const [token, setToken] = useState(localStorage.getItem('token'));
const [teamNames, setTeamNames] = useState([]);
const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleStorageChange = () => {
      setToken(localStorage.getItem('token'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const fetchTeamNames = async () => {
      setIsLoading(true);
      try {
        const response = await getUserTeamsAndTournaments(token);
        const teams = response['teams_and_tournaments'].map(item => item.team);
        setTeamNames(teams);
      } catch (error) {
        console.error('Error fetching teams', error);
      }
      setIsLoading(false);
    };

    fetchTeamNames();
  }, [token]);

  if (isLoading) {
    return <div className="mt-1 mb-5 ml-4 font-poppins text-sm text-white text-opacity-80">Chargement...</div>;
  }
  
  return (
    <div>
        {teamNames.length > 0 ? (
        teamNames.map((team, index) => (
            <div key={index}>
                <Link to={`/tournament/${team.id_tournament}`}> 
                    <h2 className='text-white text-xl font-light ml-4 mt-1 '>• {team.tournament.name_tournament}</h2>
                </Link>
                <Link to={`/tournament/${team.id_tournament}/team/${team.id_team}`}> 
                    <h1 className='text-white text-opacity-80 text-xs ml-12 mt-1 font-normal flex items-center'>
                      <img src={IconeTeam} className='w-4 mt[8px] mr-1'/>
                      {team.name_team}</h1>
                </Link>
                <br/>
            </div>
        ))
        ) : (
            <h1 className='text-white text-opacity-80 text-sm mt-1 ml-4 font-poppins mb-4'>• Aucune équipe rejointe</h1>
        )}
    </div>
  );
}

export default ViewTeamsJoined;