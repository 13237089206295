import React from 'react';
import { deleteTournament } from '../../../../api/RouteAPI';

function DeleteTournamentForm({ tournamentId, onTournamentDeleted, token }) { // Ajout de token en tant que prop

  const handleDelete = async (event) => {
    event.preventDefault();

    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce tournoi ? Cette action est irréversible.')) {
      return;
    }
  
    try {
      const response = await deleteTournament(tournamentId, token);
      if (response.status_code === 200) {
        onTournamentDeleted(tournamentId);
        window.location.reload();
      } else {
        console.error('Erreur dans la suppression du tournoi:', response.status_message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleDelete}>
      <p>Êtes-vous sûr de vouloir supprimer ce tournoi ? Cette action est irréversible.</p>
      <button type="submit" className='text-2xl'>Supprimer le tournoi</button>
    </form>
  );
}

export default DeleteTournamentForm;