import React from 'react';
import { startTournament } from '../../../../api/RouteAPI';

function StartTournamentForm({ tournamentId, onTournamentStarted }) {
  const token = localStorage.getItem('token');

  const handleStart = async (event) => {
    event.preventDefault();

    try {
      const response = await startTournament(tournamentId, token);
      if (response.status === 200) {
        onTournamentStarted(tournamentId);
        window.location.reload();
      } else {
        console.error('Erreur dans le démarrage du tournoi:', response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleStart}>
      <p>Êtes-vous sûr de vouloir démarrer ce tournoi ?</p>
      <button type="submit" className='text-2xl'>Démarrer le tournoi</button>
    </form>
  );
}

export default StartTournamentForm;