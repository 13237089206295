import React, { useState, useEffect } from 'react';
import { removeUserFromTeam } from '../../../../api/RouteAPI';

function TeamDeleteUser({ teamId, userId }) {
    const [removedUser, setRemovedUser] = useState([]);
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState({});
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!teamId || !userId) return;
    
        const fetchRemovedUser = async () => {
            try {
                const response = await removeUserFromTeam(teamId, userId, token);
                if (response.status_code === 403) {
                    setErrorMessage({ general: response.status_message });
                } else {
                    setRemovedUser(response.users);
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.status_message) {
                    setErrorMessage({ general: error.response.data.status_message });
                } else {
                    setError('Erreur lors de la suppression de l\'utilisateur de l\'équipe');
                }
                console.error(error);
            }
        };
    
        fetchRemovedUser();
    }, [teamId, userId, token]);

    return (
        <div>
            {removedUser.map(user => (
                <div key={user.id}>
                    <button onClick={() => onDeleteUser(user.pivot.id_user)}>Supprimer l'utilisateur</button>            
                </div>
            ))}
            {errorMessage.general && 
                <div className='errorMessage'>{errorMessage.general}</div>
            }
        </div>  
    );
}

export default TeamDeleteUser;