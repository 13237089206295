import React, { useState } from 'react';
import { selectWinner } from '../../../../api/RouteAPI';

const SelectWinnerForm = ({ fightId, team1, team2 }) => {
  const [id_team_winner, setIdTeamWinner] = useState('');
  const token = localStorage.getItem('token'); 

  const handleSubmit = async (event) => {
    event.preventDefault();
    await selectWinner(id_team_winner, fightId, token);
    window.location.reload();
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Sélectionnez l'équipe gagnante :
        <select 
          value={id_team_winner} 
          onChange={event => setIdTeamWinner(event.target.value)} 
          required
          className='text-black'
        >
          <option value="" class>--Sélectionnez une équipe--</option>
          {team1 && <option value={team1.id_team}>{team1.name_team}</option>}
          {team2 && <option value={team2.id_team}>{team2.name_team}</option>}
        </select>
      </label>
      <button type="submit">Sélectionner le gagnant</button>
    </form>
  );
};

export default SelectWinnerForm;