import React from 'react';
import './RegisterPage.css';
import RegisterForm from '../../components/forms/RegisterForm/RegisterForm';

function RegisterPage() {
  return (
    <div className="RegisterPage">
      <RegisterForm />
    </div>
  );
}

export default RegisterPage;