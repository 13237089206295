import React, { useState } from 'react';
import { updateScores } from '../../../../api/RouteAPI';

const UpdateScoresForm = ({ fightId }) => {
  const [score_team_winner, setScoreTeamWinner] = useState('');
  const [score_team_loser, setScoreTeamLoser] = useState('');
  const token = localStorage.getItem('token'); 

  const handleSubmit = async (event) => {
    event.preventDefault();
    await updateScores(score_team_winner, score_team_loser, fightId, token);
    window.location.reload();
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Score de l'équipe gagnante :
        <input 
          type="number" 
          value={score_team_winner} 
          onChange={event => setScoreTeamWinner(event.target.value)} 
          required 
          className="text-black"
        />
      </label>
      <label>
        Score de l'équipe perdante :
        <input 
          type="number" 
          value={score_team_loser} 
          onChange={event => setScoreTeamLoser(event.target.value)} 
          required 
          className="text-black"
        />
      </label>
      <button type="submit">Mettre à jour les scores</button>
    </form>
  );
};

export default UpdateScoresForm;