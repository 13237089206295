import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage/HomePage';
import RegisterPage from '../pages/RegisterPage/RegisterPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import SpecificTournamentPage from '../pages/SpecificTournamentPage/SpecificTournamentPage';
import ReactGA from "react-ga"; 
import '../stylesheets/index.css';
import TournamentAdminPage from '../pages/TournamentAdminPage/TournamentAdminPage';
import TeamAdminPage from '../pages/TeamAdminPage/TeamAdminPage';

import NavBar from '../components/NavBar/NavBar';


const TRACKING_ID = "G-55GKYNP8BQ";
ReactGA.initialize(TRACKING_ID);


function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
        <Router>
            <div className='bg-gradient-to-b from-background_color_top to-background_color_bottom'>
            <NavBar />
              <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/register' element={<RegisterPage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/tournament/:tournamentId' element={<SpecificTournamentPage />} />
                <Route path='/tournament/:tournamentId/update' element={<TournamentAdminPage />} />
                <Route path='/tournament/:tournamentId/team/:teamId' element={<TeamAdminPage />} />
              </Routes>
            </div>
        </Router>
  );
}

export default App;
