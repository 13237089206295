import { axiosInstance } from "./BaseAPI";


// USER ---------------------------------------------------

export const RegisterUser = async (pseudo, name, email, password, password_confirmation) => {
    const response = await axiosInstance.post('/register',  pseudo, name, email, password, password_confirmation );
    return response.data;
}


export const LoginUser = async (email, password) => {
    const response = await axiosInstance.post('/login',  email, password );
    return response.data;
}



// TOURNAMENT ---------------------------------------------------

export const createTournament = async ({name_tournament, inscription_date_start, inscription_date_end, id_game, token}) => {
    const response = await axiosInstance.post('/tournaments/create', 
        { 
            name_tournament, 
            inscription_date_start, 
            inscription_date_end, 
            id_game 
        },
        { 
            headers: { 'Authorization': `Bearer ${token}` } 
        }
    );
    return response.data;
}


export const deleteTournament = async (tournamentId, token) => {
    const response = await axiosInstance.delete(`/tournaments/delete/${tournamentId}`, 
        { 
            headers: { 'Authorization': `Bearer ${token}` } 
        }
    );
    return response.data;
}


export const startTournament = async (tournamentId, token) => {
    const response = await axiosInstance.post(`/startTournament/${tournamentId}`, 
        {
            
        }, 
        { 
            headers: { 'Authorization': `Bearer ${token}` } 
        }
    );
    return response.data;
}


export const setMatchStartDate = async (fightId, start_date, token) => {
    const response = await axiosInstance.post(`/fight/setStartDate/${fightId}`, 
        { 
            start_date 
        },
        { 
            headers: { 'Authorization': `Bearer ${token}` } 
        }
    );
    return response.data;
}


export const updateScores = async (score_team_winner, score_team_loser, fightId, token) => {
    const response = await axiosInstance.put(`/fights/scores/${fightId}`, 
        { 
            score_team_winner,
            score_team_loser
        },
        { 
            headers: { 'Authorization': `Bearer ${token}` } 
        }
    );
    return response.data;
}


export const selectWinner = async (id_team_winner, fightId, token) => {
    const response = await axiosInstance.post(`/fights/winner/${fightId}`, 
        { 
            id_team_winner
        },
        { 
            headers: { 'Authorization': `Bearer ${token}` } 
        }
    );
    return response.data;
}



// vue tournament ---------------------------------------------------
export const getAllTournaments = async () => {
    const response = await axiosInstance.get('/tournaments');
    return response.data.tournaments;
};


export const getOneTournament = async (tournamentId) => {
    const response = await axiosInstance.get(`/tournaments/${tournamentId}`);
    return response.data;
}



// Mes tournoi -----------------------------------------------------
export const getMyTournaments = async (token) => {
    const response = await axiosInstance.get('/my-tournaments', 
        {
            headers: { 'Authorization': `Bearer ${token}` }
        }
    );
    const tournaments = response.data.tournaments;
    return tournaments;
}



// TEAM ---------------------------------------------------
export const createTeam = async ({ name_team, token }, tournamentId) => {
    const response = await axiosInstance.post(`/tournaments/${tournamentId}/createTeams`, 
        {
            name_team,
        },
        { 
            headers: { 'Authorization': `Bearer ${token}` } 
        }
    );
    return response.data;
};


//voire mes team que j'ai rejoind 
export const getUserTeamsAndTournaments = async (token) => {
    const response = await axiosInstance.get('/user/team-tournament', 
        {
            headers: { 'Authorization': `Bearer ${token}` } 
        }
    );
    return response.data;
}


//voire un tournoi précis
export const getTeamAndTournament = async (tournamentId, teamId, token) => {
    const response = await axiosInstance.get(`/tournaments/${tournamentId}/teams/${teamId}`, 
        {
            headers: { 'Authorization': `Bearer ${token}` } 
        }
    );
    return response.data;
}


//voire les joueurs dans 1 team
export const getAllUsersInOneTeam = async (teamId, token) => {
    const response = await axiosInstance.get(`/teams/${teamId}/users`, 
        {
            headers: { 'Authorization': `Bearer ${token}` } 
        }
    );
    return response.data;
}


//joindre une team
export const joinTeam = async (tournamentId, teamId, token) => {
    const response = await axiosInstance.post(
        `/tournaments/${tournamentId}/joinTeams/${teamId}`,
        {},
        {
            headers: { 'Authorization': `Bearer ${token}` }
        }
    );
    return response.data;
}





// kick un user de la team
export const removeUserFromTeam = async (teamId, userId, token) => {
    const response = await axiosInstance.delete(`/teams/${teamId}/users/${userId}`, 
        {
            headers: { 'Authorization': `Bearer ${token}` } 
        }
    );
}

//voire tout les team d'un tournoi
export const getTeamsInTournament = async (tournamentId) => {
    const response = await axiosInstance.get(`/tournaments/${tournamentId}/teams`);
    return response.data;
}


// FIGHT --------------------------------------------------

export const getInitialMatches = async (tournamentId) => {
    const response = await axiosInstance.get(`/tournaments/initial-matchs/${tournamentId}`, 
    );
    return response.data;
}



// Récupere tout mes utilisateurs
export const getAllUsers = async () => {
    const response = await axiosInstance.get('/users');
    return response.data;
}