// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


/* 

.btnRegister:active {
    border-color: orange;
}



.errorMessage {
    color: red;
    font-size: 0.8vw;
    font-style: italic;
}

.divBtn2 {
    margin-left: 0.2vw;
}

.btnRegister2 {
    display: flex;
    background-color: #1C1C1C;
    padding: 1vh;
    border: 1.2px solid #4F4F4F;
    border-radius: 6px;
    width: 11.7vw;
    color: white;
    margin-right: 0.6vw;
}

.divParent {
    display: flex;
    justify-content: space-between; 
    justify-content: center;  
}

.btnSubmit{
    justify-content: center;
    margin-top: 4vh;
    margin-left: 10.5vw;
    background-color: #1C1C1C;
    padding: 2vh;
    border: 1px solid #FFA800;
    border-radius: 6px;
}

.dejaCompte{
    margin-left:7vw;
}


.meconnecter{
    margin-left: 0.8vw;
} */`, "",{"version":3,"sources":["webpack://./src/components/forms/RegisterForm/RegisterForm.css"],"names":[],"mappings":";;;AAGA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAoDG","sourcesContent":["\n\n\n/* \n\n.btnRegister:active {\n    border-color: orange;\n}\n\n\n\n.errorMessage {\n    color: red;\n    font-size: 0.8vw;\n    font-style: italic;\n}\n\n.divBtn2 {\n    margin-left: 0.2vw;\n}\n\n.btnRegister2 {\n    display: flex;\n    background-color: #1C1C1C;\n    padding: 1vh;\n    border: 1.2px solid #4F4F4F;\n    border-radius: 6px;\n    width: 11.7vw;\n    color: white;\n    margin-right: 0.6vw;\n}\n\n.divParent {\n    display: flex;\n    justify-content: space-between; \n    justify-content: center;  \n}\n\n.btnSubmit{\n    justify-content: center;\n    margin-top: 4vh;\n    margin-left: 10.5vw;\n    background-color: #1C1C1C;\n    padding: 2vh;\n    border: 1px solid #FFA800;\n    border-radius: 6px;\n}\n\n.dejaCompte{\n    margin-left:7vw;\n}\n\n\n.meconnecter{\n    margin-left: 0.8vw;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
