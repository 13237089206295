import React, { useEffect, useState } from 'react';
import { getOneTournament } from '../../api/RouteAPI';
import { useParams, useNavigate } from 'react-router-dom';
import DeleteTournamentForm from '../../components/forms/ForAdminTournamentPage/DeleteTournamentForm/DeleteTournamentForm';
import StartTournamentForm from '../../components/forms/ForAdminTournamentPage/StartTournamentForm/StartTournamentForm';
import ViewAdminFightInTournament from '../../components/forms/ForAdminTournamentPage/ViewAdminFightInTournament/ViewAdminFightInTournament';

function TournamentUpdatePage() {
  const [tournament, setTournament] = useState(null);
  const { tournamentId } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem('token'); 

  useEffect(() => {
    const fetchTournament = async () => {
      try {
        const response = await getOneTournament(tournamentId);
        if (response.status_code === 200) {
          setTournament(response.tournament);
        } else {
          setError(response.message);
        }
      } catch (error) {
        console.error('Error fetching tournament:', error);
        setError('An error occurred');
      }
    };

    fetchTournament();
  }, [tournamentId]);

  const handleTournamentDeleted = () => {
    navigate('/'); // Redirige vers la page d'accueil ou une autre page
  };

  const handleTournamentStart = () => {
    navigate('/'); // Redirige vers la page d'accueil ou une autre page
  };

  if (!tournament) {
    return <h1>Aucun tournoi trouvé</h1>;
  }

  return (
    <div className="text-white">
      <h2>{tournament.name_tournament}</h2>
      <p>Created by: {tournament.id_user_createur}</p>
      <p>Status: {tournament.status}</p>
      <p>Start: {tournament.inscription_date_start}</p>
      <p>End: {tournament.inscription_date_end}</p>
      
      <br/>
      <br/>
      <br/>

      <DeleteTournamentForm tournamentId={tournamentId} onTournamentDeleted={handleTournamentDeleted} token={token} />

      <br/>
      <br/>
      <br/>

      <StartTournamentForm tournamentId={tournamentId} token={token} />

      <br/>
      <br/>
      <br/>


      <br/>
      <br/>
      <br/>

      <ViewAdminFightInTournament tournamentId={tournamentId} />

      <br/>
      <br/>
      <br/>

    </div>
  );
}

export default TournamentUpdatePage;
