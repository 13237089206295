import React, { useState } from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, BellIcon} from '@heroicons/react/24/outline';

import CreateTournament from '../forms/CreateTournamentForm/CreateTournamentForm';
import LoginForm from '../forms/LoginForm/LoginForm';

import ViewTournamentJoin from '../forms/ViewTournamentJoin/ViewTournamentJoin';
import ViewTournamentCreate from '../forms/ViewTournamentCreate/ViewTournamentCreate';

import IconRejoindre from '../../assets/icons/JoinTourna.png';
import IconHelp from '../../assets/icons/helpAndTrial.png';
import IconRejoindreOrange from '../../assets/icons/bxs-meteor.png';
import IconHelpOrange from '../../assets/icons/bx-library.png';
import IconRowLeft from '../../assets/icons/bxs-arrow-from-left.png';
import IconRowRight from '../../assets/icons/bxs-arrow-from-right.png';

const navigation = [
  { name: 'Rejoindre un tournoi', href: '/', current: false, icon: IconRejoindre },
  { component: <CreateTournament /> },
  { name: 'Aide', href: '/', current: false, icon: IconHelp },
];

export default function NavBar() {
  const [isHoveredRejoindre, setIsHoveredRejoindre] = useState(false);
  const [isHoveredAide, setIsHoveredAide] = useState(false);
  const [isOpen, setIsOpen] = useState(false);


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <>
        <Popover
          as="header"
          className="pt-6 bg-transparent shadow-sm lg:static lg:overflow-y-visible"
        >
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative flex justify-between lg:gap-8 xl:grid xl:grid-cols-12">
              <div className="flex items-center md:static xl:col-span-2">
                <div className="flex flex-shrink-0 items-center">
                  <a href="/" className="text-white font-poppins font-bold text-2xl tracking-wide">
                    Korvalan
                    <span className="text-orange_FFA800 font-poppins text-base font-bold">.</span>
                  </a>
                </div>              
              </div>

              <div className="pt-1 flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden">
                <PopoverButton className="group relative -mx-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange_FFA800">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                  <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                </PopoverButton>
              </div>

              <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-8 xl:col-start-5">

                <a
                  href="/"
                  className="ml-6 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:text-orange_FFA800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange_FFA800 transition duration-500 ease-in-out"
                  onMouseEnter={() => setIsHoveredRejoindre(true)}
                  onMouseLeave={() => setIsHoveredRejoindre(false)}
                >
                  <div className="relative h-5 w-5 mr-2">
                    <img
                      src={IconRejoindre}
                      alt="Icon Help"
                      className={`absolute inset-0 h-full w-full transition-opacity duration-500 ease-in-out ${isHoveredRejoindre ? 'opacity-0' : 'opacity-100'}`}
                    />
                    <img
                      src={IconRejoindreOrange}
                      alt="Icon Help Orange"
                      className={`absolute inset-0 h-full w-full transition-opacity duration-500 ease-in-out ${isHoveredRejoindre ? 'opacity-100' : 'opacity-0'}`}
                    />
                  </div>
                  Rejoindre un tournoi
                </a>


                <CreateTournament />
            

                <a
                  href="/"
                  className="ml-6 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:text-orange_FFA800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange_FFA800 transition duration-500 ease-in-out"
                  onMouseEnter={() => setIsHoveredAide(true)}
                  onMouseLeave={() => setIsHoveredAide(false)}
                >
                  <div className="relative h-5 w-5 mr-2">
                    <img
                      src={IconHelp}
                      alt="Icon Help"
                      className={`absolute inset-0 h-full w-full transition-opacity duration-500 ease-in-out ${isHoveredAide ? 'opacity-0' : 'opacity-100'}`}
                    />
                    <img
                      src={IconHelpOrange}
                      alt="Icon Help Orange"
                      className={`absolute inset-0 h-full w-full transition-opacity duration-500 ease-in-out ${isHoveredAide ? 'opacity-100' : 'opacity-0'}`}
                    />
                  </div>
                        Aide
                </a>
              
              
                <LoginForm />


              </div>
            </div>
          </div>

          <PopoverPanel
            as="nav"
            aria-label="Global"
            className="rounded-lg absolute top-20 left-2 right-2 z-50 lg:hidden border border-gray_bottom bg-background_color_top bg-opacity-95 transition-colors duration-200 ease-in-out"
          >
            <div className="mx-auto max-w-3xl space-y-1 px-2 pb-3 pt-2 sm:px-4">

              <a
                  href="/"
                  className="ml-6 inline-flex items-center rounded-md px-3 py-2 text-xs font-bold text-white shadow-sm hover:text-orange_FFA800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange_FFA800 transition duration-500 ease-in-out"
                  onMouseEnter={() => setIsHoveredRejoindre(true)}
                  onMouseLeave={() => setIsHoveredRejoindre(false)}
                >
                  <div className="relative h-5 w-5 mr-2">
                    <img
                      src={IconRejoindre}
                      alt="Icon Help"
                      className={`absolute inset-0 h-full w-full transition-opacity duration-500 ease-in-out ${isHoveredRejoindre ? 'opacity-0' : 'opacity-100'}`}
                    />
                    <img
                      src={IconRejoindreOrange}
                      alt="Icon Help Orange"
                      className={`absolute inset-0 h-full w-full transition-opacity duration-500 ease-in-out ${isHoveredRejoindre ? 'opacity-100' : 'opacity-0'}`}
                    />
                  </div>
                  Rejoindre un tournoi
                </a>


                <CreateTournament />
            

                <a
                  href="/"
                  className="ml-6 inline-flex items-center rounded-md px-3 py-2 text-xs font-bold text-white shadow-sm hover:text-orange_FFA800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange_FFA800 transition duration-500 ease-in-out"
                  onMouseEnter={() => setIsHoveredAide(true)}
                  onMouseLeave={() => setIsHoveredAide(false)}
                >
                  <div className="relative h-5 w-5 mr-2">
                    <img
                      src={IconHelp}
                      alt="Icon Help"
                      className={`absolute inset-0 h-full w-full transition-opacity duration-500 ease-in-out ${isHoveredAide ? 'opacity-0' : 'opacity-100'}`}
                    />
                    <img
                      src={IconHelpOrange}
                      alt="Icon Help Orange"
                      className={`absolute inset-0 h-full w-full transition-opacity duration-500 ease-in-out ${isHoveredAide ? 'opacity-100' : 'opacity-0'}`}
                    />
                  </div>
                        Aide
                </a>

                <div className="border-t border-gray-200 opacity-70 pt-4 pr-2"/>

              <LoginForm />

            


            

              {/* <div className="mx-auto flex max-w-3xl items-center px-4 sm:px-6">
                <div className="flex-shrink-0">

                  <div className="bg-gray-600 rounded-full h-10 w-10 flex items-center justify-center">
                    <span className="text-sm text-white">{user.name.charAt(0)}</span>
                    </div>
                  </div>

                  <div className="ml-3">
                    <div className="text-base font-medium text-orange_FFA800 text-opacity-100">{user.pseudo}</div>
                    <div className="text-sm font-medium text-gray-500">{user.email}</div>                
                  </div>

                  <button
                      type="button"
                      className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                    <BellIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div> */}

              </div>
          </PopoverPanel>
        </Popover>  

        <div
          as="header"
          className="max-w-full overflow-x-hidden bg-transparent shadow-sm data-[open]:fixed data-[open]:inset-0 data-[open]:z-10 data-[open]:overflow-y-auto lg:static lg:overflow-y-visible data-[open]:lg:static data-[open]:lg:overflow-y-visible"
        >
          <div className=" overflow-x-hidden mt-20 sm:mt-16 absolute top-0 left-0 right-0 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 z-30">
            <div className="relative flex justify-between lg:gap-8 xl:grid xl:grid-cols-12">
              <div className="flex items-center md:static xl:col-span-2">
                <div className="flex flex-shrink-0 items-center">
                  <div className="relative flex justify-between lg:gap-8 xl:grid xl:grid-cols-12">
                    <div className="flex flex-col pt-2">
                      <div className="relative inline-block">
                        <button
                          onClick={toggleMenu}
                          className={`z-10 px-3 sm:px-4 py-1 sm:py-2 text-xs font-medium text-white focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-gray_bottom bg-gradient-to-r from-background_color_top_bouton to-background_color_bottom_bouton rounded-xl shadow border border-[#7c7c7c] transition-all duration-500 ease-in-out transform 
                          ${
                            isOpen ? 'sm:w-96 w-56' : 'w-32'
                          }`}
                        >
                          Mes tournois
                          <img src={IconRowLeft} className='relative inline-block w-4 ml-1 mb-[1px]'></img>

                          <div
                            className={`transition-all duration-200 ease-in-out transform ${
                              isOpen ? 'max-h-96 opacity-100 translate-x-0' : 'max-h-0 opacity-0'
                            }`}
                          >
                            <div className="pt-6" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                              <p className='text-gray_bottom text-xs font-normal font-inter text-left'>Tournoi rejoint</p>
                              <p className='text-white text-sm font-medium text-left mt-2'><ViewTournamentJoin/></p>
                              <hr className='border-gray_dark_lane mb-4'/>
                              <p className='text-gray_bottom text-xs font-normal font-inter text-left'>Tournoi créé</p>
                              <p className='text-white text-sm font-medium text-left mt-2 mb-2'><ViewTournamentCreate/></p>
                            </div>
                          </div>
                        </button>
                      </div>                 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>          
      </>
    </div>
  );
}