import React, { useEffect, useState } from 'react';
import { getMyTournaments } from '../../../api/RouteAPI';
import { Link } from 'react-router-dom';

function ViewTournamentCreate() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [tournamentNames, setTournamentNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const handleStorageChange = () => {
      setToken(localStorage.getItem('token'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  
  useEffect(() => {
    const fetchTournamentNames = async () => {
      setIsLoading(true);
      try {
        if (token) {
          const tournaments = await getMyTournaments(token);
          const names = Object.values(tournaments); 
          setTournamentNames(names);
        }
      } catch (error) {
        console.error('Error fetching tournaments', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchTournamentNames();
  }, [token]);


 
  if (isLoading) {
    return <div className="ml-4 mt-1 font-poppins text-sm text-white text-opacity-80">Chargement...</div>;
  }


  return (
    <div>
      {tournamentNames.length > 0 ? (
        tournamentNames.map((tournament, index) => (
          <Link to={`/tournament/${tournament.id_tournament}/update`} key={index}>
            <h1 className=' text-white text-xl font-light ml-4 mt-1 '>• {tournament.name_tournament}</h1>
          </Link>
        ))
      ) : (
        <h1 className='text-white text-opacity-80 text-sm ml-4 mt-1 font-poppins mb-4'>• Aucun tournoi créé</h1>
      )}
    </div>
  );
}

export default ViewTournamentCreate;