import React, { useState, useEffect } from 'react';
import { getInitialMatches } from '../../../../api/RouteAPI';
import './ViewAdminFightInTournament.css'; 
import SetMatchStartDateForm from '../SetMatchStartDateForm/SetMatchStartDateForm';
import UpdateScoresForm from '../UpdateScoresForm/UpdateScoresForm';
import SelectWinnerForm from '../SelectWinnerForm/SelectWinnerForm';


const Team = ({ seed, name, score }) => (
  <div className="team">
    <span className="seed">{seed}</span>
    <span className="name">{name}</span>
    <span className="score text-orange-300">{score}</span>
  </div>
);

const Match = ({ match }) => {
  const [showStartDateForm, setShowStartDateForm] = useState(false);
  const [showScoresForm, setShowScoresForm] = useState(false);
  const [showWinnerForm, setShowWinnerForm] = useState(false);
  const { team1, team2 } = match; 

  const handleSetStartDate = () => {
    setShowStartDateForm(true);
  };
  
  const handleUpdateScores = () => {
    setShowScoresForm(true);
  };

  const handleSelectWinner = () => {
    setShowWinnerForm(true);
  };


  return (
    <div className="match">
      <div className="match-top">
        <Team 
          seed={match.team1 ? match.team1.seed : 'N/A'} 
          name={match.team1 ? match.team1.name_team : 'N/A'} 
          score={match.score_team_winner}
        />
      </div>
      <div className="match-bottom">
        <Team 
          seed={match.team2 ? match.team2.seed : 'N/A'} 
          name={match.team2 ? match.team2.name_team : 'N/A'} 
          score={match.score_team_loser}
        />
      </div>
      <div className="match-lines">
        <div className="line one"></div>
        <div className="line two"></div>
      </div>
      <div className="match-lines alt">
        <div className="line one"></div>
      </div>
      <button onClick={handleSetStartDate}>Définir la date de début</button>
      {showStartDateForm && <SetMatchStartDateForm fightId={match.id_fight} />}
      <button onClick={handleUpdateScores}>Mettre à jour les scores</button>
      {showScoresForm && <UpdateScoresForm fightId={match.id_fight} />}
      <button onClick={handleSelectWinner}>Sélectionner le gagnant</button>
      {showWinnerForm && <SelectWinnerForm fightId={match.id_fight} team1={team1} team2={team2}/>}
    </div>
  );
};

const Column = ({ matches }) => (
  <div className="column">
    {matches.map(match => (
      <Match key={match.id_fight} match={match} />
    ))}
  </div>
);

const TournamentMatches = ({ tournamentId }) => {
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    const fetchMatches = async () => {
      const data = await getInitialMatches(tournamentId);
      setMatches(data);
    };

    fetchMatches();
  }, [tournamentId]);

  const groupedMatches = groupMatchesByRound(matches);

  return (
    <div className="bracket">
      {Object.keys(groupedMatches).map(round => (
        <Column key={round} matches={groupedMatches[round]} />
      ))}
    </div>
  );
};

const groupMatchesByRound = (matches) => {
  const grouped = {};
  matches.forEach(match => {
    const round = match.tournament_round;
    if (!grouped[round]) {
      grouped[round] = [];
    }
    grouped[round].push(match);
  });
  return grouped;
};

export default TournamentMatches;