import React, { useState, useEffect } from 'react';
import { createTournament } from '../../../api/RouteAPI';
import Modal from 'react-modal';
import ReactGA from 'react-ga';
import LogoDestiny from '../../../assets/img/icon-destiny.png';
import LogoLol from '../../../assets/img/icon-lol.png';

import IconCreer from '../../../assets/icons/CreaTourna.png';
import IconRejoindre from '../../../assets/icons/JoinTourna.png';
import IconHelp from '../../../assets/icons/helpAndTrial.png';
import IconCreerOrange from '../../../assets/icons/bxs-coffee.png';
import IconRejoindreOrange from '../../../assets/icons/bxs-meteor.png';
import IconHelpOrange from '../../../assets/icons/bx-library.png';



Modal.setAppElement('#root');

function CreateTournamentForm({ isModalOpen, setIsModalOpen }) {
  const [name_tournament, setName] = useState('');
  const [inscription_date_end, setEndDate] = useState('');
  const [id_game, setGame] = useState('');
  const [errorMessage, setErrorMessage] = useState({});
  const [selectedGame, setSelectedGame] = useState(null);

  const [isHoveredRejoindre, setIsHoveredRejoindre] = useState(false);
  const [isHoveredCreer, setIsHoveredCreer] = useState(false);
  const [isHoveredAide, setIsHoveredAide] = useState(false);


  const gameOptions = {
    lol: { '1v1': 4, '2v2': 5, '3v3': 6, '4v4': 7, '5v5': 2 },
    destiny: { '1v1': 8, '2v2': 9, '3v3': 10, '4v4': 11, '5v5': 12, '6v6': 13 },
  };

  const handleClick = async () => {
    const token = localStorage.getItem('token');
    const formattedDate = inscription_date_end.replace('T', ' ');
    const tournamentData = {
      name_tournament,
      inscription_date_end: formattedDate,
      id_game,
      token,
    };
    

    try {
      const response = await createTournament(tournamentData);
      if (response.errorsList) {
        setErrorMessage(response.errorsList);
      } else {
        setIsModalOpen(false); // Close the modal upon successful creation
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }

    ReactGA.event({
      category: 'User',
      action: 'Clicked Register Button',
    });
  };

  return (
    <div>
      
      <button onClick={() => setIsModalOpen(true)} 
        className="ml-6 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:text-orange_FFA800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange_FFA800 transition duration-500 "
        onMouseEnter={() => setIsHoveredCreer(true)}
        onMouseLeave={() => setIsHoveredCreer(false)}
      >
        <div className="relative h-5 w-5 mr-2">
                  <img
                    src={IconCreer}
                    alt="Icon Help"
                    className={`absolute inset-0 h-full w-full transition-opacity duration-500 ease-in-out ${isHoveredCreer ? 'opacity-0' : 'opacity-100'}`}
                  />
                  <img
                    src={IconCreerOrange}
                    alt="Icon Help Orange"
                    className={`absolute inset-0 h-full w-full transition-opacity duration-500 ease-in-out ${isHoveredCreer ? 'opacity-100' : 'opacity-0'}`}
                  />
                </div>

          Créer un tournoi
      </button>

    <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="md:min-h-2/3 md:w-4/12 grid-cols-2 m-auto bg-[#1C1C1C] mt-32 mb-9 rounded-lg"
        overlayClassName="fixed inset-0 bg-black bg-opacity-70 z-[1100]"
    >
        <div>
        <h2 className='text-white text-3xl font-poppins font-semibold text-center pt-8'>Création de tournoi<span className='text-orange_FFA800'>.</span></h2>

        <div className='mt-10'>
            <h1 className='font-poppins text-white font-bold text-xs ml-[7.5em]'>Nom tournoi</h1>
            <div className='flex flex-col items-center justify-center'>
                <input 
                    type="text" 
                    value={name_tournament} 
                    onChange={e => setName(e.target.value)} 
                    className='bg-[#1C1C1C] p-[1vh] border-[1.2px] border-[#4F4F4F] rounded-[6px] w-[24vw] text-white font-poppins font-medium mt-1'
                />
            </div>
            {errorMessage.name_tournament && 
            <div className='text-red-500 mt-2 text-center w-full'>{errorMessage.name_tournament.join(', ')}</div>}
        </div>


          <div className="flex flex-col justify-center items-center pt-6">
            <h1 className='w-full mx-auto font-poppins text-white font-bold text-xs text-center mb-1'>Sélection du jeu</h1>            
                <div className="flex justify-center items-center mx-auto space-x-4">
                    <div className={`p-1 rounded-lg border ${selectedGame === 'lol' ? 'border-orange_FFA800' : 'border-gris'} hover:border-orange_FFA800`}>
                        <img src={LogoLol} alt="lol" onClick={() => setSelectedGame('lol')} className="w-[54px] h-[49px]"/>
                    </div>
                    <div className={`p-1 rounded-lg border ${selectedGame === 'destiny' ? 'border-orange_FFA800' : 'border-gris'} hover:border-orange_FFA800`}>
                        <img src={LogoDestiny} alt="destiny" onClick={() => setSelectedGame('destiny')} className="w-[54px] h-[52px]"/>
                    </div>
                </div>
            {selectedGame && (
                <select className='flex bg-[#1C1C1C] p-[1vh] border-[1.2px] border-[#4F4F4F] rounded-[6px] w-[24vw] text-white font-poppins font-medium mt-6' onChange={e => setGame(gameOptions[selectedGame][e.target.value])}>
                <option value="" className='flex bg-[#1C1C1C] p-[1vh] border-[1.2px] border-[#4F4F4F] rounded-[6px] w-[24vw] text-white font-poppins font-medium mt-1'>Sélectionnez un format</option>
                {Object.keys(gameOptions[selectedGame]).map(option => (
                    <option key={option} value={option} className='flex bg-[#1C1C1C] p-[1vh] border-[1.2px] border-[#4F4F4F] rounded-[6px] w-[24vw] text-white font-poppins font-medium mt-1'>
                    {option}
                    </option>
                ))}
                </select>
            )}
            {errorMessage.id_game && 
                <div className='text-red-500 mt-2'>{errorMessage.id_game.join(', ')}</div>}
          </div>


          <br />
          <br />



        <h1 className='font-poppins text-white font-bold text-xs ml-[7.5em]'>date de fin</h1>
            <div className='flex flex-col items-center justify-center'>
                <input
                    type="datetime-local"
                    value={inscription_date_end}
                    onChange={e => setEndDate(e.target.value)}
                    className='bg-[#1C1C1C] p-[1vh] border-[1.2px] border-[#4F4F4F] rounded-[6px] w-[24vw] text-white font-poppins font-medium mt-1'
                />
                {errorMessage.inscription_date_end && (
                    <div className='text-red-500 mt-2'>{errorMessage.inscription_date_end.join(', ')}</div>
                )}
            </div>


          <br />
          <br />


        </div>
        <div className='pb-10 flex justify-center items-center ml-20'>
            <button onClick={handleClick} className='inline-center mr-20 items-center rounded-md px-4 py-2 text-xs border-2 border-orange_FFA800 text-orange_FFA800 font-medium ring-1 ring-inset ring-gray-500/10 transition duration-300 hover:bg-orange_FFA800 hover:text-dark_grey'>
            Créer
            </button>
            <button onClick={() => setIsModalOpen(false)} className='text-white'></button>
        </div>
      </Modal>
    </div>
  );
}

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      ReactGA.event({
        category: 'User',
        action: 'Opened Register Form',
      });
    }
  }, [isModalOpen]);

  return (
    <div>
      <CreateTournamentForm isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
}

export default App;
