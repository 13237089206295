import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-grey text-center py-4 border-t border-gray-700 ml-12 mr-12">
      <div className="text-sm flex justify-center items-center text-white">Korvalan<div className="text-orange_FFA800 text-sm">.</div>
      </div>
      <div className="text-sm text-white">© 2024 Korvalan Inc. Tout droit réservé.</div>
    </footer>
  );
};

export default Footer;