import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { differenceInSeconds } from 'date-fns';
import { getOneTournament, getAllUsers, getAllTournaments } from '../../api/RouteAPI';
import CreateTeamForm from '../../components/forms/ForSpecificTournamentPage/CreateTeamForm/CreateTeamForm';
import ViewAllTeamInTournament from '../../components/forms/ViewAllTeamInTournament/ViewAllTeamInTournament';
import ViewFightInTournament from '../../components/forms/ViewFightInTournament/ViewFightInTournament';
import BottomBar from '../../components/forms/BottomBar/BottomBar';
import iconlol from '../../assets/img/icon-lol.png';
import icondestiny from '../../assets/img/icon-destiny.png';

function TournamentDetail() {
    const { tournamentId } = useParams();
    const [tournament, setTournament] = useState(null);
    const [usernames, setUsernames] = useState({});
    const [secondsLeft, setSecondsLeft] = useState(null);

    useEffect(() => {
        getOneTournament(tournamentId)
            .then(data => {
                setTournament(data.tournament);
                setSecondsLeft(differenceInSeconds(new Date(data.tournament.inscription_date_end), new Date()));
            })
            .catch(error => console.error(error));
    }, [tournamentId]);

    useEffect(() => {
        const fetchTournamentsAndUsers = async () => {
            try {
                const [tournamentsData, usersData] = await Promise.all([
                    getAllTournaments(),
                    getAllUsers()
                ]);

                const usernamesMap = {};
                usersData.users.forEach(user => {
                    usernamesMap[user.id] = user.pseudo;
                });
                setUsernames(usernamesMap);
            } catch (error) {
                console.error(error);
            }
        };

        fetchTournamentsAndUsers();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (tournament) {
                const remainingSeconds = differenceInSeconds(new Date(tournament.inscription_date_end), new Date());
                if (remainingSeconds <= 0) {
                    setSecondsLeft(0);
                    clearInterval(interval);
                } else {
                    setSecondsLeft(remainingSeconds);
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [tournament]);

    if (!tournament) {
        return <div>Error</div>;
    }

    const creatorPseudo = usernames[tournament.id_user_createur] || 'Unknown';

    const getGameIcon = (id) => {
        if ([1, 2, 3, 4, 5, 6, 7].includes(id)) {
            return iconlol;
        } else if ([8, 9, 10, 11, 12, 13].includes(id)) {
            return icondestiny;
        } else {
            return '';
        }
    };

    const gameIcon = getGameIcon(tournament.id_game);

    return (
        <div className="text-white">
            <div className="flex items-center justify-between bg-gris p-4 mb-6">
                <div className="flex items-center">
                    <img src={gameIcon} alt="icon" className="w-8 h-8 mr-4"/>
                    <span className="text-sm font-semibold">
                        Nom de Lan: <div className='text-orange_FFA800 italic'>{tournament.name_tournament}</div>
                    </span>
                </div>
                <span className="text-sm">organisateur: <span className="italic text-orange_FFA800">{creatorPseudo}</span></span>
                <span className="text-sm">status: <span className="italic text-orange_FFA800">{tournament.status}</span></span>
                /
                <span className="text-sm">Classement</span>
                <span className="text-sm">Les équipes</span>
                <span className="text-sm">Planning tournoi</span>
            </div>

            {tournament.status === 'inscription' && (
                <>
                    <p className="text-2xl text-white text-center bg-light_grey mt-10 mr-40 ml-40 p-4 rounded-md border-2 border-orange_FFA800">
                        Fin d'inscription : {new Date(secondsLeft * 1000).toISOString().substr(11, 8)}
                    </p>
                    <br/>
                    <br/>
                    <CreateTeamForm tournamentId={tournamentId} />
                    <br/>
                    <br/>
                </>
            )}

            <ViewFightInTournament tournamentId={tournamentId} />

            <br/>
            <br/>

            <ViewAllTeamInTournament tournamentId={tournamentId}/>

            <BottomBar/>
        </div>
    );
}

export default TournamentDetail;
