import React, { useState } from 'react';
import { createTeam } from '../../../../api/RouteAPI';
import ReactGA from "react-ga"; 

function CreateTeamForm({ tournamentId }) {
    const [name_team, setNameTeam] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleClick = async () => {
        const token = localStorage.getItem('token');
        const teamData = { 
            name_team,
            token: token
        };

        try {
            const response = await createTeam(teamData, tournamentId);
            if (response.status_code === 201) {
                // Réinitialiser le formulaire ou rediriger l'utilisateur en cas de succès
                window.location.reload();
            } else {
                setErrorMessage(response.status_message);
                console.error(response.status_message);
            }
            console.log(response);
        } catch (errors) {
            if (errors.response && errors.response.data && errors.response.data.status_message) {
                setErrorMessage(errors.response.data.status_message);
            } else {
                setErrorMessage('Une erreur est survenue lors de la création de l\'équipe');
            }
            console.error(errors);
        }

        ReactGA.event({
            category: 'User',
            action: 'Clicked Register Button'
        });
    };

    return (
        <div className="bg-gris p-6 rounded-lg shadow-lg w-full max-w-md mx-auto">
            <h2 className="text-3xl font-bold text-center text-white mb-4">Crée une équipe</h2>
            <div className="mb-4">
                <label className="labelInput font-poppins text-white font-bold text-xs ml-5">
                    Nom de l'équipe
                </label>
                <input 
                    id="teamName"
                    type="text" 
                    value={name_team} 
                    onChange={e => setNameTeam(e.target.value)} 
                    className='flex bg-[#1C1C1C] p-[1vh] border-[1.2px] border-[#4F4F4F] rounded-[6px] w-[19vw] text-white font-poppins font-medium mt-1 ml-5'
                />
                {errorMessage && (
                    <div className='text-red-500 mt-2'>{errorMessage}</div>
                )}
            </div>
            <div className="flex justify-center w-full">
                <button 
                    type="button" 
                    onClick={handleClick} 
                    className='inline-flex items-center rounded-md px-4 py-2 text-xs border-2 border-orange_FFA800 text-orange_FFA800 font-medium ring-1 ring-inset ring-gray-500/10 transition duration-300 hover:bg-orange_FFA800 hover:text-gray-900'
                >
                    Créer
                </button>
            </div>
        </div>
    );
};

export default CreateTeamForm;