import React, { useState, useEffect } from 'react';
import { getTeamsInTournament, joinTeam } from '../../../api/RouteAPI';

const ViewAllTeamInTournament = ({ tournamentId }) => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const data = await getTeamsInTournament(tournamentId);
        setTeams(data.teams);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchTeams();

    // Clean up message and message type when component is unmounted or tournamentId changes
    return () => {
      setMessage('');
      setMessageType('');
    };
  }, [tournamentId]);

  const handleJoinTeam = async (teamId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await joinTeam(tournamentId, teamId, token);
      console.log(response);
      setMessage('Vous avez rejoint l\'équipe avec succès !');
      setMessageType('success');
      setTimeout(() => window.location.reload(), 1500);  // Rafraîchir la page après 2 secondes
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.status_message) {
        setMessage(error.response.data.status_message);
      } else {
        setMessage('Une erreur s\'est produite lors de la tentative de rejoindre l\'équipe.');
      }
      setMessageType('error');
    }
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (error) {
    return <div>Erreur : {error.message}</div>;
  }

  return (
    <div className="flex flex-wrap justify-center w-full px-4 py-8 relative">
      {message && (
        <div className={`mb-4 ${messageType === 'error' ? 'text-red-500' : 'text-green-500'} italic absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
          {message}
        </div>
      )}
      {teams && teams.map(team => {
        const leader = team.users.find(user => user.id === team.id_users_leader);
        return (
          <div key={team.id_team} className="bg-gris w-full md:w-1/3 lg:w-1/4 p-6 m-4 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-white">Team <span className='orange_FFA800'>{team.name_team}</span></h2>
            <h3 className="text-xl font-semibold mb-2">Participant</h3>
            <div className="space-y-2">
              {team.users && team.users.map(user => (
                <div key={user.id} className="flex items-center space-x-2">
                  <div className="bg-gray-600 rounded-full h-8 w-8 flex items-center justify-center">
                    <span className="text-sm">{user.pseudo.charAt(0)}</span>
                  </div>
                  <div className={`${user.id === team.id_users_leader ? 'italic font-semibold' : ''}`}>
                    {user.pseudo} {user.id === team.id_users_leader && '[Leader]'}
                  </div>
                </div>
              ))}
            </div>
            <button
              className="mt-4 py-2 px-4 rounded inline-center mr-20 items-center rounded-md px-4 py-2 text-xs border-2 border-orange_FFA800 text-orange_FFA800 font-medium ring-1 ring-inset ring-gray-500/10 transition duration-300 hover:bg-orange_FFA800 hover:text-dark_grey"
              onClick={() => handleJoinTeam(team.id_team)}
            >
              Rejoindre
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default ViewAllTeamInTournament;
