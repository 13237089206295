import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga"; 
import HeaderInfos from "../../components/Helmet/Helmet";
import TournamentList from '../../components/forms/ForHomePage/getAllTournaments/getAllTournaments';
import BottomBar from '../../components/forms/BottomBar/BottomBar';
import { Button } from '@headlessui/react';


function HomePage({ openModal }) {
  return (
    <div>
        <h1 className=''>BUILDING</h1>
        <Link to="/join-tournament" className="text-white">Joindre un tournoi</Link>
        <br/>
        <h2 className='text-white'>dadzda</h2>
    </div>
  );
}



function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

 

  return (
    <div>
      <div className='mt-20'>
        <TournamentList/>
      </div>

      <HeaderInfos
        title="korvalan"
        description="Découvret le site d'organisateur de tournoi"
        url="https://www.korvalan.com/"
        author = "korvalan"
        follow = "follow"
        robots = "index, follow"
      />

      <BottomBar />
    </div>
  );
}

export default App;