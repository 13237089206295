import React, { useState } from 'react';
import { setMatchStartDate } from '../../../../api/RouteAPI';

const SetMatchStartDateForm = ({ fightId }) => {
  const [start_date, setStartDate] = useState('');
  const token = localStorage.getItem('token'); 

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedDate = start_date.replace('T', ' '); // Formatte la date en 'YYYY-MM-DD HH:mm'
    await setMatchStartDate(fightId, formattedDate, token);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Date de début :
        <input 
          className="text-black"
          type="datetime-local" 
          value={start_date} 
          onChange={event => setStartDate(event.target.value)} 
          required 
        />
      </label>
      <button type="submit">Définir la date de début</button>
    </form>
  );
};

export default SetMatchStartDateForm;