import React, { useState, useEffect } from 'react';
import { getAllUsersInOneTeam } from '../../../../api/RouteAPI';

function TeamUsersList({ teamId, onDeleteUser }) {
    const [usersInfo, setUsersInfo] = useState([]);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchUsersInfo = async () => {
          try {
            const response = await getAllUsersInOneTeam( teamId, token);
            setUsersInfo(response.users);
          } catch (error) {
            setError('Erreur lors de l\'affichage des joueur dans l\'équipe');
            console.error(error);
          }
        };
    
        fetchUsersInfo();
      }, [ teamId, token]);
    
      if (error) {
        return <div>{error}</div>;
      }

      return (
        <div>
          {usersInfo.map(user => (
            <div key={user.id}>
              <h2>{user.name}</h2>
              <p>Pseudo: {user.pseudo}</p>
              <p>Email: {user.email}</p>
              <p>Email Verified At: {user.email_verified_at || 'Not Verified'}</p>
              <p>User ID: {user.pivot.id_user}</p>
              <p>Team ID: {user.pivot.id_team}</p>
              <button onClick={() => onDeleteUser(user.pivot.id_user)}>Supprimer l'utilisateur</button>            
            </div>
          ))}
        </div>  
      );
}

export default TeamUsersList;